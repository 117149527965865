import React, { Component } from 'react'
import { connect } from 'kea'
import PropTypes from 'prop-types'
import newsletter from '../../../kea/newsletter'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import './Newsletter.scss'

@connect({
  props: [
    newsletter, [
      'isSubmitted',
      'hasFailed',
      'isLoading',
      'isNewsletterTaken'
    ],
  ],
  actions: [
    newsletter, [
      'orderNewsletter',
      'setNewsletterTaken'
    ]
  ]
})
class Newsletter extends Component {
  canShowNewsletter = false
  constructor (props) {
    super(props)

    this.state = {
    }
    if (!props.isNewsletterTaken && !props.showAlways) {
      this.canShowNewsletter = true
      props.actions.setNewsletterTaken(this)
    }
  }

  handleSubmit = () => {
    window.open('https://uutiskirjeet.tekniikanmaailma.fi/', '_blank')
  }

  componentWillUnmount () {
    if (this.canShowNewsletter) {
      this.canShowNewsletter = false
      this.actions.setNewsletterTaken(null)
    }
  }

  render () {
    if (!this.canShowNewsletter && !this.props.showAlways) {
      return null
    }
    return (
      <div styleName={this.props.wide ? 'container wide' : 'container'}>
        <h4 styleName="title">Tilaa Tekniikan Maailman uutiskirjeet</h4>
        <div styleName="content">
          <p><b>Tilaa TM-uutiskirje,</b> niin pysyt ajan tasalla autoalan, teknologian ja tieteen uutisista! Saat TM-uutiskirjeen joka arkipäivä.</p>
          <p><span style={{
            color: '#e4001e',
            fontFamily: 'Guardian TextSans Bold',
            fontWeight: 'bold'
          }}>UUTUUS:</span><b> TM Tiede & teknologia -uutiskirje</b> kokoaa sinulle parhaat juttumme tieteestä ja teknologiasta kerran viikossa.</p>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div styleName="fields" onKeyDown={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
            onTouchMove={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}>
            <div styleName="boxes2">
              <button onClick={this.handleSubmit}>Tilaa</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Newsletter.propTypes = {
  isSubmitted: PropTypes.bool,
  isNewsletterTaken: PropTypes.object,
  actions: PropTypes.object,
  hasFailed: PropTypes.bool,
  isLoading: PropTypes.bool,
  wide: PropTypes.bool,
  showAlways: PropTypes.bool,
}

export default withErrorBoundary(
  Newsletter,
  ErrorPlaceholderBox()
)
